@import '../../../styles/reference';

.Product {
  &Modal {
    &Alert {
      color: $pds-color-neutral-900;
      background-color: $pds-color-neutral-100;
      border: rem-calc(2px) solid $pds-color-neutral-300;
    }
  }
}
